import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

// icons
import { FaTrashAlt } from "react-icons/fa";
import { MdOutlineContactMail } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import ContatoServices from '../services/contato.services';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from 'react-google-recaptcha';
import { FaCheckCircle } from "react-icons/fa";
import InputMask from 'react-input-mask';
import Header from '../components/header';
import { env } from '../env';
import api from "./../services/api";
import styles from './style.module.css';

export default function ContatoForm() {

    const [captcha, setCaptcha] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const fileinputref = useRef(null);
    const [assunto, setAssunto] = useState(null);
    const [cdAssunto, setCdAssunto] = useState(null);
    const [protcoloText, setProtcoloText] = useState("");

    useEffect(() => {

        // Função para obter parâmetro da URL quando o componente for montado
        const params = new URLSearchParams(window.location.search);
        const parametroAssunto = params.get('assunto');
        if (parametroAssunto === "")
            toast.warn("Assunto do formulário nao foi definido!");

        const service = new ContatoServices();
        // eslint-disable-next-line
        var assunto = service.getAssuntos().filter(c => c.codigo == parametroAssunto);

        if (assunto.length > 0) {
            setAssunto(assunto[0].descricao)
            setCdAssunto(assunto[0].codigo);
        }
        else
            toast.error('Assunto inválido!');

    }, []);


    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        fone: '',
        mensagem: '',
        protocolo: '',
        arquivo: [],
        files: [],
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true); // Inicia o estado de carregamento 

        if (!captcha) {
            toast.warning("Captcha não foi resolvido.");
            setIsLoading(false); // Finaliza o estado de carregamento, independentemente do resultado
        } else {

            var form = formData;
            form.assunto = assunto;
            setFormData(formData);

            try {

                const response = await api.post('/ocorrencias/cadastrar', formData);

                // eslint-disable-next-line
                if (response.status == 200) {
                    toast.success("Cadastrado com sucesso, protocolo: " + response.data["idprocess"]);
                    resets();
                } else {
                    toast.error("Atenção: " + response.message);
                }

            } catch (error) {
                if (error.response !== undefined)
                    toast.error(error.response.data["message"]);
            } finally {
                setIsLoading(false); // Finaliza o estado de carregamento, independentemente do resultado
            }
        }

    }

    function resets() {
        var reset = {
            nome: '',
            email: '',
            fone: '',
            mensagem: '',
            protocolo: '',
            arquivo: [],
            files: [],
        }
        setFormData(reset);
    }


    const handleRemoveFile = (index) => {
        const newFiles = [...formData.files];
        newFiles.splice(index, 1);
        setFormData({
            ...formData,
            files: newFiles,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleProtocolo = async (e) => {
        e.preventDefault();

        var form = formData;
        form.protocolo = e.target.value;
        setFormData(formData);

        try {

            const response = await api.post('/ocorrencias/protocolo', formData);

            // eslint-disable-next-line
            if (response.status == 200) {
                setProtcoloText(response.data[0].nmprocess);
            } else {
                setProtcoloText("");
            }


        } catch (error) {
            setProtcoloText("");
        } finally {
            setIsLoading(false); // Finaliza o estado de carregamento, independentemente do resultado
        }
    }

    const handleAdicionarArquivo = (e) => {
        const files = Array.from(fileinputref.current.files);
        setFormData({
            ...formData,
            files: [...formData.files, ...files],
        });
        // Limpar o campo de input de arquivo
        fileinputref.current.value = '';
    }


    return (<>
        <Header />
        <h1 className={styles.xTitulo}>Fale Conosco - {assunto}</h1>

        <div className="container" style={{ width: '90%' }}>
            <form onSubmit={handleSubmit}>

                <div className="row">
                    <fieldset style={{ border: '1px dashed #ccc', padding: '10px', margin: 15 }} className='col'>
                        <Form.Group className="mb-2" controlId="formName">
                            <Form.Label><strong>Nome: <span className='text-danger'>*</span></strong></Form.Label>
                            <Form.Control
                                type="text"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <div className="form-group mb-3">
                            <label className="form-label"><strong>E-mail: <span className='text-danger'>*</span></strong></label>
                            <div className='input-group'>
                                <input type="text"
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="form-control" placeholder="seuemail@exemplo.com" />
                                <span className="input-group-text" id="basic-addon2"><MdOutlineContactMail /></span>
                            </div>
                        </div>


                        <Form.Group controlId="formPhone">
                            <Form.Label><strong>Telefone: <span className='text-danger'>*</span></strong></Form.Label>
                            <InputMask
                                name='fone'
                                mask="(99) 99999-9999"
                                maskChar=" "
                                value={formData.fone}
                                onChange={handleChange}
                                required
                            >
                                {(inputProps) => <Form.Control type="tel" name="phone" {...inputProps} />}
                            </InputMask>
                        </Form.Group>

                        {cdAssunto == 8 ?
                            <Form.Group className='col-8' controlId="formProtocolo">
                                <Form.Label><strong>Protocolo (Nº SAC): <span className='text-danger'>*</span></strong></Form.Label>
                                <input
                                    type="text"
                                    name='protocolo'
                                    value={formData.protocolo}
                                    onChange={handleChange}
                                    onBlur={handleProtocolo}
                                    required
                                    className="form-control" />
                                {protcoloText != "" ? <> <FaCheckCircle />
                                    <span>{protcoloText}</span>
                                </> : ""}
                            </Form.Group>
                            : ''}
                    </fieldset>


                    <fieldset style={{ border: '1px dashed #ccc', padding: '10px', margin: 15 }} className='col'>
                        <label htmlFor="anexos"><strong>Anexos: </strong></label>
                        <div className="form-group mb-3">
                            <div className="input-group">
                                <input
                                    type="file"
                                    name='arquivo'
                                    ref={fileinputref}
                                    className="form-control" />
                                <button
                                    className="bt-def" style={{
                                        padding: '0', margin: '0', borderRadius: 0, paddingRight: 5
                                    }}
                                    onClick={handleAdicionarArquivo}
                                    type="button" id="button-addon2">
                                    <span style={{ paddingLeft: 10, paddingRight: 10 }}>Confirmar</span>
                                </button>
                            </div>
                        </div>

                        <table className="table table-responsive" >
                            <thead>
                                <tr>
                                    <th style={{ width: '100px', fontSize: 12, textAlign: 'center' }} scope="col">#</th>
                                    <th style={{ fontSize: 12, width: '100%' }} scope="col">Nome do arquivo</th>
                                    <th style={{ width: '200px', fontSize: 12, textAlign: 'center' }} scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.files.map((file, index) => (
                                    <tr key={index}>
                                        <td clas style={{ width: '100px', fontSize: 12, textAlign: 'center' }} scope="row">{index + 1}</td>
                                        <td style={{ fontSize: 12 }}>{file.name}</td>
                                        <td style={{ width: '200px', fontSize: 12, textAlign: 'right' }} >
                                            <button
                                                type='button' className="btn btn-danger" onClick={() => handleRemoveFile(index)}><FaTrashAlt /></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </fieldset>
                </div>


                <Form.Group controlId="formMessage">
                    <Form.Label><strong>Mensagem: <span className='text-danger'>*</span></strong></Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="mensagem"
                        value={formData.mensagem}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <div className="d-flex align-content-center flex-wrap p-3">
                    <ReCAPTCHA
                        sitekey={env.REACT_APP_SITE_KEY}
                        onChange={setCaptcha} />
                </div>

                <div className="col-12 d-flex justify-content-center mb-3">
                    <button type="submit" disabled={isLoading} className='bt-def'>
                        <RiSendPlaneFill /> {isLoading ? 'Enviando...' : 'Enviar'}
                    </button>
                </div>
            </form>
        </div>

        <ToastContainer />
        <div className='footer'>

        </div>
    </>
    );
}
