import axios from "axios";
import { env } from "../env";

const api = axios.create({
    baseURL: env.REACT_APP_BASEURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Basic ' + env.REACT_APP_SERVER_USR + ':' + env.REACT_APP_SERVER_PASS
    }
});

export default api;