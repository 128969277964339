

class ContatoServices {


    getAssuntos() {
        return [
            {
                "codigo": 1,
                "descricao": "01 - DÚVIDAS"
            },
            {
                "codigo": 2,
                "descricao": "02 - ELOGIOS"
            }, {
                "codigo": 3,
                "descricao": "03 - RECLAMAÇÃO"
            },
            {
                "codigo": 4,
                "descricao": "04 - SOLICITAÇÃO"
            },
            {
                "codigo": 5,
                "descricao": "05 - SUGESTÃO",
            },
            {
                "codigo": 6,
                "descricao": "SAC",
            },
            {
                "codigo": 7,
                "descricao": "ELOGIO",
            },
            {
                "codigo": 8,
                "descricao": "OUVIDORIA",
            }
        ]
    }



}

export default ContatoServices;