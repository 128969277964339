import styles from './style.module.css';

export default function Header() {
    return (
        <>
            <div className={styles.infodata}></div>
            <header className={styles.header}>
                <div id="header-content" style={{ marginLeft: '90px' }}>
                    <nav id="nav" role="navigation">
                        <div className="nav-logo">
                            <div className="box-logo">
                                <img alt="logo"
                                    className={styles.img}
                                    style={{ maxHeight: '60px' }}
                                    src="https://cdn.cmtecnologia.com.br/03-clientes/logos/tecnolab_logo.png" />
                            </div>
                        </div>

                    </nav>
                </div>
            </header>
        </>
    );
}