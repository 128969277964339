import './App.css';
import ContatoForm from './pages/ContatoForm';

function App() {
  return (
    <ContatoForm />
  );
}

export default App;
